







































import { Vue, Component } from 'vue-property-decorator'
import { teamsStore } from '@/store'

interface Item {
  title: string,
  value: boolean,
  action: () => void
}

@Component({
  components: {
    IconMore: () => import('@tada/icons/dist/IconMore.vue'),
  },
})
export default class MeetingsActions extends Vue {
  private get items (): Item[] {
    const items = []
    items.push(this.viewModeItem)
    return items
  }

  private get viewModeItem (): Item {
    return {
      title: this.$t('meetings.rightBar.actions.shortView').toString(),
      action: () => console.log('!!!!'),
      value: teamsStore.getters.currentTeam.me.taskShortView ?? false,
    }
  }
}
